<template>
  <div class="w-screen h-screen flex items-center justify-center bg-gradient-lavender text-white">
    <div class="standard-spacing">
      <div class="flex items-center">
        <h1 class="heading-1">
          {{ error.statusCode }} {{ error.statusCode === '404' ? 'Page not found' : 'An error occurred' }}
        </h1>
      </div>
      <div class="flex justify-end mt-16">
        <a href="/" class="px-6 py-4 bg-white text-black">Back to home</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    props: ['error'],
    head: {
      title: 'Hoap • Something went wrong',
    },
  }
</script>
